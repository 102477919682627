<div [ngClass]="{'h-[200px]': showFilters, 'h-0': !showFilters}"
    class="top-[118px] sm:top-0 left-0 bg-gray-200 fixed sm:relative sm:h-auto px-4 sm:px-6 lg:px-8 border border-gray-300 border-b-1 border-t-0 border-l-0 border-r-0 sm:pb-2 transition-height delay-150 duration-300 overflow-hidden w-full"
    style="box-shadow: inset 0px 1px 3px 1px rgb(0 0 0 / 22%); background-color: #e2eaf2 !important; z-index: 10000;">
    <ul role="list" class="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-4 xl:grid-cols-4">
        <li class="relative col-span-1 flex rounded-md">
            <mat-form-field [style.width.%]="100">
                <mat-label><i class="fa-solid fa-calendar-days mr-1"></i>
                    {{
                    "features.home.SEARCH.START_DATE" | translate
                    }}</mat-label>
                <input mbsc-datepicker variant="outlined" [mbscOptions]="datepickerOptionsStartDate" matInput [ngModel]="startDate"
                    (ngModelChange)="startDateChange($event)" />
            </mat-form-field>
        </li>
        <li class="relative col-span-1 flex rounded-md">
            <mat-form-field [style.width.%]="100">
                <mat-label>{{ 'features.home.SEARCH.DURATION' | translate}}</mat-label>
                <mat-select [ngModel]="duration" (ngModelChange)="durationChange($event)">
                    <mat-option *ngFor="let hour of hours" [value]="hour">
                        {{hour}}
                    </mat-option>
                </mat-select>
                <i *ngIf="duration" class="cursor-pointer fa-solid fa-xmark text-gray-500 absolute right-5 top-2"
                    (click)="clearDuration($event)"></i>
            </mat-form-field>
        </li>
    </ul>

    <div class="bottom-0 shadow absolute text-center left-0 right-0 sm:hidden m-auto cursor-pointer w-[200px] py-1 px-4 rounded-t-lg"
        style="background-color: #c8c8c8;" (click)="toggleFilters()">
        <i class="fa-solid fa-sliders mr-2"></i>
        <span>{{ 'features.equipment.HIDE_FILTERS' | translate}}</span>
    </div>
</div>