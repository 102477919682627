import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EquipmentService {
  public equipment: Subject<any> = new Subject<any>();

  constructor() { }

  updateEquipment(param: boolean): void {
    this.equipment.next(param);
  }

  getModifyEquipment(): Observable<boolean> {
    return this.equipment.asObservable();
  }
}
