import {
  Component,
  OnInit,
  Inject,
  AfterViewInit,
  Output,
  Input,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogService } from '@app/services/common/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, takeUntil } from 'rxjs';
import { VfmBaseComponent } from 'src/app/features/VfmBaseComponent';
import { ReservationEquipmentSelectComponent } from '../reservation-equipment-select/reservation-equipment-select.component';
import { ReservationWorkingShiftDialogComponent } from '../reservation-working-shift-dialog/reservation-working-shift-dialog.component';
import { WorkingShiftEditComponent } from 'src/app/features/profile/components/working-shift-edit/working-shift-edit.component';
import { RecurringDateComponent } from './components/recurring-date/recurring-date.component';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MbscDatepickerOptions, localeEn, localeFr } from '@mobiscroll/angular';
import { Equipment, Reservation, UserProfile } from 'src/app/data/index';
import { EquipmentService } from '@app/services/data/equipment.service';
import { ReservationService } from '@app/services/data/reservation.service';
import { UserProfileService } from '@app/services/data/user-profile.service';
import { ToastrService } from 'ngx-toastr';
import { CustomValidators } from 'src/app/core/providers/CustomValidators';
import * as moment from 'moment';
import { UserRoleService } from '@app/services/common/user-role.service';
import { ROLES } from '@app/constants/roles.constants';
import { SHIFTS } from 'src/app/core/constants/shifts.constants';
import { ProfileService } from 'src/app/core/services/common/profile.service';
import { Shift, ShiftTemplate } from 'src/app/data';
import { ShiftService } from '@app/services/data/shift.service';

@Component({
  selector: 'app-reservation-add-detail',
  templateUrl: './reservation-add-detail.component.html',
  styleUrls: ['./reservation-add-detail.component.scss'],
})
export class ReservationAddDetailComponent
  extends VfmBaseComponent
  implements OnInit, OnChanges
{
  selectedStartDate: any;
  selectedEndDate: any;
  selectedEquipment!: Equipment;
  tabIndex = 0;
  showDurationMessage = false;
  startDateFromRoute?: moment.Moment;
  durationFromRoute?: number;

  @ViewChild(RecurringDateComponent) recurringDateComponent!: any;

  @Output() equipment: EventEmitter<Equipment> = new EventEmitter<Equipment>();
  @Output() startDate: EventEmitter<string> = new EventEmitter<string>();
  @Output() endDate: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectUser: EventEmitter<any> = new EventEmitter<any>();
  @Output() displayShift: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() recurringRule: EventEmitter<any> = new EventEmitter<any>();
  @Output() recurring: EventEmitter<any> = new EventEmitter<any>();
  @Output() reccurenceExclusions: EventEmitter<any> = new EventEmitter<any>();
  @Input() dateUpdated!: any;
  @Input() isReservationOverlap!: boolean;
  @Input() modificationDate!: any;

  locale = this.profile.language === 0 ? localeEn : localeFr;

  datepickerOptionsEndDate: MbscDatepickerOptions = {
    moment: moment,
    returnFormat: 'moment',
    select: 'date',
    controls: ['calendar', 'time'],
    stepMinute: 5,
    timeFormat: 'HH:mm',
    invalid: [
      {
        start: '1900-01-01',
        end: moment().add(-1, 'day').format('YYYY-MM-dd'),
      },
    ],
    onClose: (event, inst) => {
      const formValues = this.reservationform.getRawValue();
      let startDate = moment(formValues.startDate);
      let endDate = moment(formValues.endDate);

      if (event.valueText !== '' && startDate > endDate) {
        let startDate = moment(event.valueText).add(-1, 'hour');

        this.reservationform.controls['startDate'].patchValue(startDate);
      }
    },
    locale: this.locale,
  };

  datepickerOptionsStartDate: MbscDatepickerOptions = {
    moment: moment,
    returnFormat: 'moment',
    select: 'date',
    controls: ['calendar', 'time'],
    stepMinute: 5,
    timeFormat: 'HH:mm',
    invalid: [
      {
        start: '1900-01-01',
        end: moment().add(-1, 'day').format('YYYY-MM-dd'),
      },
    ],
    onClose: (event, inst) => {
      const formValues = this.reservationform.getRawValue();
      let startDate = moment(formValues.startDate);
      let endDate = moment(formValues.endDate);

      if (event.valueText !== '' && startDate > endDate) {
        let endDate = moment(event.valueText).add(1, 'hour');

        this.reservationform.controls['endDate'].patchValue(endDate);
      }
    },
    locale: this.locale,
  };

  user = this.translate.instant('labels.USER');
  calendarSelectedDate: any = new Date();

  tempEvent: any;
  isFormSaved: boolean = false;
  reservationform!: FormGroup;
  reservationId!: number;
  isEdit = false;
  hasRequestor = false;
  selectedUser!: UserProfile | null;
  shifts!: ShiftTemplate[];
  reccuringRule: string = '';
  isRecurring = false;
  isApplyShift = false;
  isShift = false;
  userShiftName: string = '';

  isAdmin = false;
  equipmentId: number | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private equipmentService: EquipmentService,
    private toastrService: ToastrService,
    private reservationService: ReservationService,
    private userProfileService: UserProfileService,
    private roleUserService: UserRoleService,
    private profileService: ProfileService,
    private shiftService: ShiftService
  ) {
    super();
    const state = this.router.getCurrentNavigation()?.extras.state;
    
    if(state){
      if(state.startDate)
      {
        this.startDateFromRoute = moment(state.startDate);
        this.durationFromRoute = state.duration || 1;
      }
    }
  }

  async ngOnInit() {
    const equipmentId = this.route.snapshot.paramMap.get('equipmentId');
    const reservationId = this.route.snapshot.paramMap.get('id');

    this.selectedUser = this.profile;

    this.loadShiftsTemplate();

    if (!this.roleUserService.getAccessToken()) {
      await this.roleUserService.fetchAccessToken();
    }

    const roles = this.roleUserService.getRoles() || [];
    const siteManagerRole = ROLES.SITE_MANAGER.replace(
      '{siteId}',
      this.site.id
    );
    const sitePlanerRole = ROLES.SITE_PLANER.replace('{siteId}', this.site.id);
    const requiredRoles = [siteManagerRole, sitePlanerRole, ROLES.ADMIN];

    if (requiredRoles.some((r) => roles.includes(r))) {
      this.isAdmin = true;
    }

    this.selectUser.emit(this.selectedUser);

    if (reservationId) {
      this.isEdit = true;
      this.reservationId = +reservationId;
      this.loadReservation(this.reservationId);
    }

    this.buildReservationForm();

    if (equipmentId) {
      this.equipmentId = +equipmentId;

      this.equipmentService
        .getEquipmentById(+equipmentId, this.site.code)
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          this.updateEquipment(result);
        });
    }

    this.reservationform.get('user')?.valueChanges.subscribe((val) => {
      if (val && val.length > 0) {
        if (isNaN(val[0].id)) {
          this.getUserByObjectId(val[0].id);
        } else {
          this.getUser(val[0].id);
        }
      } else {
        this.selectedUser = null;
        this.userShiftName = '';
        this.selectUser.emit(null);
      }
    });

    this.reservationform.get('startDate')?.valueChanges.subscribe((val) => {
      const startDate = moment(val).format('YYYY-MM-DD[T]HH:mm');

      this.startDate.emit(startDate);
    });

    this.reservationform.get('endDate')?.valueChanges.subscribe((val) => {
      const endDate = moment(val).format('YYYY-MM-DD[T]HH:mm:ss');
      this.endDate.emit(endDate);
    });

    let startDate: moment.Moment;
    let endDate: moment.Moment;

    if (this.isEdit === false) {
      if(this.startDateFromRoute)
      {
        startDate = this.startDateFromRoute;
        endDate = startDate.clone().add(this.durationFromRoute, 'hour');
      }
      else
      {
        startDate = moment();
        endDate = startDate.clone().add(1, 'hour');
      }
      
      this.reservationform.controls['startDate'].patchValue(
        startDate.format('YYYY-MM-DD[T]HH:mm:ss')
      );
      this.reservationform.controls['endDate'].patchValue(
        endDate.format('YYYY-MM-DD[T]HH:mm:ss')
      );
    }

    setTimeout(() => {
      if (!this.isEdit) {
        this.setMyUser();
      }
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dateUpdated && changes.dateUpdated.currentValue) {
      let startDate = moment(changes.dateUpdated.currentValue.startDate).format(
        'YYYY-MM-DD[T]HH:mm:ss'
      );

      let endDate = moment(changes.dateUpdated.currentValue.endDate).format(
        'YYYY-MM-DD[T]HH:mm:ss'
      );

      console.log('startDate', startDate);
      console.log('endDate', endDate);

      this.reservationform.controls['startDate'].patchValue(startDate);
      this.reservationform.controls['endDate'].patchValue(endDate);
    }
  }

  setMyUser() {
    this.reservationform.controls['user'].patchValue([
      {
        id: this.profile.objectId,
        displayName: this.profile.name,
      },
    ]);
  }

  setUser(reservation: Reservation) {
    this.reservationform.controls['user'].patchValue([
      {
        id: reservation.userId,
        displayName: reservation.userName,
      },
    ]);
  }

  setRequestor(reservation: Reservation) {
    this.reservationform.controls['requestor'].patchValue(
      reservation.requestor?.name
    );
  }

  /**
   * Return true if the user has not made any changes still unsaved
   */
  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.reservationform.dirty && !this.isFormSaved) {
      return this.dialogService.confirm(
        this.translate.instant('features.addReservation.CREW_UNSAVED_CHANGES'),
        this.translate.instant('features.addReservation.CONFIRM_CANCEL')
      );
    }

    return true;
  }

  /**
   * define the form for reservation entry
   */
  private buildReservationForm() {
    this.reservationform = this.formBuilder.group({
      id: [''],
      equipmentName: ['', Validators.required],
      name: [''],
      description: [''],
      user: [[]],
      userId: [''],
      eventName: [''],
      rRule: [''],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      sitecode: [this.site.code],
      requestor: [''],
    });
  }

  /**
   * Get the form control for the Description
   */
  get descriptionCtrl() {
    return this.reservationform.get('descritpion') as FormControl;
  }

  /**
   * Get the form control for the Requestor
   */
  get requestorCtrl() {
    return this.reservationform.get('requestor') as FormControl;
  }

  /**
   * Get the form control for the category Name
   */
  get equipmentNameCtrl() {
    return this.reservationform.get('equipmentName') as FormControl;
  }

  /**
   * Get the form control for the Start Date
   */
  get startDateCtrl() {
    return this.reservationform.get('startDate') as FormControl;
  }

  /**
   * Get the form control for the End Date
   */
  get endDateCtrl() {
    return this.reservationform.get('endDate') as FormControl;
  }

  /**
   * Get the form control for the rRule
   */
  get rRuleCtrl() {
    return this.reservationform.get('rRule') as FormControl;
  }

  selectEquipment() {
    const dialogRef = this.dialog.open(ReservationEquipmentSelectComponent, {
      width: '750px',
      height: '650px',
      panelClass: 'equipment-select',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.updateEquipment(result);
      }
    });
  }

  updateEquipment(equipment: Equipment) {
    this.selectedEquipment = equipment;
    this.reservationform.controls['equipmentName'].patchValue(
      this.selectedEquipment.name
    );

    this.getEquipment(equipment.id!);
    this.showDurationMessage = this.ShowDurationMessage();
  }

  selectWorkingShift() {
    const dialogRef = this.dialog.open(ReservationWorkingShiftDialogComponent, {
      width: '750px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.profile = result;
        this.profileService.updateProfile(this.profile);
        this.getUserShift();
      }
    });
  }

  get nameCtrl() {
    return this.reservationform.get('name') as FormControl;
  }

  monthChange(e: Event) {}

  onDeleteClick() {}

  loadReservation(reservationId: number) {
    this.reservationService
      .getReservationsById(this.site.code, reservationId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        //Date of modification should correspond to the chosen date on the series
        if (this.modificationDate) {
          result.startDate = moment(
            new Date(
              +moment(this.modificationDate).format('YYYY'),
              +moment(this.modificationDate).format('M') - 1,
              +moment(this.modificationDate).format('D'),
              moment(result.startDate).hour(),
              moment(result.startDate).minute()
            ).toString()
          ).format('YYYY-MM-DD[T]HH:mm');

          result.endDate = moment(
            new Date(
              +moment(this.modificationDate).format('YYYY'),
              +moment(this.modificationDate).format('M') - 1,
              +moment(this.modificationDate).format('D'),
              moment(result.endDate).hour(),
              moment(result.endDate).minute()
            )
          ).format('YYYY-MM-DD[T]HH:mm');
        } else {
          result.startDate = moment(result.startDate).format(
            'YYYY-MM-DD[T]HH:mm:ss'
          );
          result.endDate = moment(result.endDate).format(
            'YYYY-MM-DD[T]HH:mm:ss'
          );
        }

        this.reservationform.patchValue(result);

        this.setUser(result);
        this.setRequestor(result);
        this.hasRequestor = result.requestor && true;
        this.getUser(result.userId!);

        // get equipment related to the reservation
        this.getEquipment(result.equipmentId!);

        this.isRecurring = result.rRule ? true : false;

        const recurringException = [
          ...result.exclusions,
          ...result.reccurenceExclusions.filter(Boolean),
        ];

        this.reccurenceExclusions.emit(recurringException);
      });
  }

  loadShiftsTemplate() {
    this.shiftService.getShiftTemplate().subscribe((result: any) => {
      this.shifts = result;
    });
  }

  save() {
    if (this.isRecurring) {
      this.recurringDateComponent.getCustomRule();
    }

    if (this.reservationform.invalid) {
    } else {
      const formValues = this.reservationform.getRawValue();

      const reservation: Reservation = {
        startDate: moment(formValues.startDate).format('YYYY-MM-DD[T]HH:mm:ss'),
        endDate: moment(formValues.endDate).format('YYYY-MM-DD[T]HH:mm:ss'),
        objectId: this.selectedUser!.objectId,
        eventName: '',
        description: formValues.description,
        id: this.reservationId,
        rRule: '',
        shiftTemplateId: null,
        shiftStartDate: null,
        shiftEndDate: null,
        modifiedAt: this.modificationDate,
      };

      if (this.isShift) {
        reservation.rRule = this.reccuringRule;
      }

      if (this.isShift && this.isApplyShift) {
        reservation.shiftTemplateId = this.profile.shiftTemplateId;
        reservation.shiftStartDate = this.profile.shiftStartDate;
        reservation.shiftEndDate = this.profile.shiftEndDate;
      }

      const save = this.reservationId
        ? 'updateReservation'
        : 'createReservation';

      this.equipmentService[save](
        this.site.code,
        this.selectedEquipment.id!,
        reservation
      )
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.toastrService.success(
            this.translate.instant(
              this.reservationId
                ? 'features.addReservation.UPDATE_RESERVATION_SUCCESS'
                : 'features.addReservation.CREATE_RESERVATION_SUCCESS'
            )
          );
          this.goToReservationList();
        });
    }
  }

  getUser(id: number) {
    this.userProfileService
      .getUserProfile(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.selectedUser = result;
        this.selectUser.emit(this.selectedUser);
        this.getUserShift();
      });
  }

  getUserByObjectId(objectId: string) {
    this.userProfileService
      .getUserProfileByObjectId(objectId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.selectedUser = result;
        this.selectUser.emit(this.selectedUser);
        this.getUserShift();
      });
  }

  getEquipment(equipmentId: number) {
    this.equipmentService
      .getEquipmentById(equipmentId, this.site.code)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.selectedEquipment = result;
        this.reservationform.controls['equipmentName'].patchValue(
          this.selectedEquipment.name
        );
        this.equipment.emit(this.selectedEquipment);
      });
  }

  cancel() {
    this.goToReservationList();
  }

  /**
   * Return to reservation List
   */
  private goToReservationList() {
    if (this.isAdmin) {
      if (this.equipmentId) {
        this.router.navigate([
          this.router.url.split('/').slice(0, -2).join('/'),
        ]);
      } else {
        this.router.navigate(['/admin/reservation']);
      }
    } else {
      if (this.equipmentId) {
        this.router.navigate([
          this.router.url.split('/').slice(0, -2).join('/'),
        ]);
      } else {
        this.router.navigate(['/reservation']);
      }
    }
  }

  onTabChanged(event: any) {
    this.tabIndex = event.index;
  }

  onShiftChanged(event: any) {
    if (this.profile.shiftTemplateId) this.displayShift.emit(event);
  }

  getUserShift() {
    if (this.selectedUser!.shiftTemplateId > 0 && this.shifts) {
      let userShift = this.shifts.find(
        (shift) => shift.id === this.selectedUser!.shiftTemplateId
      );
      this.userShiftName = userShift!.name;
    } else {
      this.userShiftName = 'No shift selected';
    }
  }

  onRecurringRuleChange(event: any) {
    this.reccuringRule = event;
    this.recurringRule.emit(event);
  }

  onRecurringChange(event: any) {
    this.isShift = event;
    this.recurring.emit(event);
  }

  public ShowDurationMessage() {
    return (
      this.selectedEquipment &&
      this.selectedEquipment.restrictReservationDuration
    );
  }
}
