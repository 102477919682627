import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { PaginatedItems } from 'src/app/data';
import {
  Equipment,
  Reservation,
  Device,
  Geofence,
  DevicePosition,
} from 'src/app/data/index';
import { handleError } from './handleError';

const CAT_API_EQUIPMENT = 'equipment/site';
export type EquipmentCreateRequest = Omit<Equipment, 'id' | 'siteName'>;
export type EquipmentUpdateRequest = Omit<Equipment, 'siteName'>;

export interface EquipmentRequest {
  siteCode: string;
  crewId: number;
  categoryId: number;
  pictureUri: string;
  pictureName: string;
  name: string;
  unit: string;
  brand: string;
  model: string;
  description: string;
  tags: string[];
  isActive: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class EquipmentService {
  constructor(private http: HttpClient) {}

  /**
   * Get PaginatedItems Result
   * @returns an observable of PaginatedItems<Equipment>
   */
  getEquipments(sitecode: string, pageSize = 0, pageIndex = 0) {
    return this.http
      .get<PaginatedItems<Equipment>>(
        `${CAT_API_EQUIPMENT}/${sitecode}?pageSize=${pageSize}&pageIndex=${pageIndex}`
      )
      .pipe(catchError(handleError));
  }

  /**
   * Get PaginatedItems Result
   * @returns an observable of PaginatedItems<Equipment>
   */
  getAvailableEquipments(sitecode: string, startDate: string, duration: number, pageSize = 0, pageIndex = 0) {
    const url = `${CAT_API_EQUIPMENT}/${sitecode}/available`;
    
    let params = new HttpParams()
      .append('pageSize', pageSize)
      .append('pageIndex', pageIndex)
      .append('startDate', startDate)
      .append('durationInHours', duration);
    
    return this.http
      .get<PaginatedItems<Equipment>>(
        url, {params}
      )
      .pipe(catchError(handleError));
  }

  /**
   * Get PaginatedItems Result
   * @returns an observable of PaginatedItems<Equipment>
   */
  getEquipmentsByCrewId(
    sitecode: string,
    crewId: number,
    pageSize = 0,
    pageIndex = 0
  ) {
    return this.http
      .get<PaginatedItems<Equipment>>(
        `${CAT_API_EQUIPMENT}/${sitecode}/bycrew/${crewId}?pageSize=${pageSize}&pageIndex=${pageIndex}`
      )
      .pipe(catchError(handleError));
  }

  getEquipmentById(id: number, sitecode: string) {
    return this.http
      .get<Equipment>(`${CAT_API_EQUIPMENT}/${sitecode}/${id}`)
      .pipe(catchError(handleError));
  }

  /**
   * Get PaginatedItems Result
   * @returns an observable of PaginatedItems<Reservation>
   */
  getReservationsByEquipment(
    sitecode: string,
    id: number,
    pageSize = 0,
    pageIndex = 0
  ) {
    return this.http
      .get<PaginatedItems<Reservation>>(
        `${CAT_API_EQUIPMENT}/${sitecode}/${id}/reservation?pageSize=${pageSize}&pageIndex=${pageIndex}`
      )
      .pipe(catchError(handleError));
  }

  /**
   * Create a new reservation
   * @param equipment
   * @returns
   */
  public createReservation(
    sitecode: string,
    id: number,
    reservation: Reservation
  ) {
    return this.http
      .post<EquipmentRequest>(
        `${CAT_API_EQUIPMENT}/${sitecode}/${id}/reservation`,
        reservation
      )
      .pipe(catchError(handleError));
  }

  /**
   * Stop current reservation
   * @param equipment
   * @returns
   */
  public stopCurrentReservation(
    sitecode: string,
    id: number,
    reservationId: number
  ) {
    return this.http
      .put<EquipmentRequest>(
        `${CAT_API_EQUIPMENT}/${sitecode}/${id}/reservation/stop/${reservationId}`,
        null
      )
      .pipe(catchError(handleError));
  }

  /**
   * Update a reservation
   * @param equipment
   * @returns
   */
  public updateReservation(
    sitecode: string,
    id: number,
    reservation: Reservation
  ) {
    return this.http
      .put<EquipmentRequest>(
        `${CAT_API_EQUIPMENT}/${sitecode}/${id}/reservation/${reservation.id}`,
        reservation
      )
      .pipe(catchError(handleError));
  }

  /**
   * Delete a reservation
   * @param equipment
   * @returns
   */
  public deleteReservation(
    sitecode: string,
    id: number,
    reservationId: number
  ) {
    return this.http
      .delete<EquipmentRequest>(
        `${CAT_API_EQUIPMENT}/${sitecode}/${id}/reservation/${reservationId}`
      )
      .pipe(catchError(handleError));
  }

  /**
   * Save or update a category
   * @param category to save
   * @returns an observable of the saved category
   */
  saveEquipment(sitecode: string, equip: Equipment) {
    if (equip.id) {
      return this.updateEquipment(sitecode, equip);
    }
    return this.createEquipment(sitecode, equip);
  }

  /**
   * @returns an observable of Reservation
   */
  searchEquipment(
    sitecode: string,
    searchText: string,
    CategoryId: string,
    CrewId: string,
    isActive: boolean | string,
    isFavorite: boolean | string
  ) {
    return this.http
      .get<Reservation>(
        `${CAT_API_EQUIPMENT}/${sitecode}/search?pageSize=0&pageIndex=0&searchText=${searchText}&CategoryId=${CategoryId}&CrewId=${CrewId}&isActive=${isActive}&isFavorite=${isFavorite}`
      )
      .pipe(catchError(handleError));
  }

  /**
   * Create a new equipment
   * @param equipment
   * @returns
   */
  private createEquipment(sitecode: string, equipment: Equipment) {
    const newEquip: EquipmentRequest = this.equipmentRequestFactory(
      equipment,
      sitecode
    );

    return this.http
      .post<EquipmentRequest>(`${CAT_API_EQUIPMENT}/${sitecode}`, newEquip)
      .pipe(catchError(handleError));
  }

  /**
   * get availabilities
   * @param sitecode
   * @param equipmentId
   * @returns
   */
  public getAvailabilitiesByEquipmentId(
    sitecode: string,
    equipmentId: number,
    pageSize = 10,
    pageIndex = 0
  ) {
    return this.http
      .get<PaginatedItems<Reservation>>(
        `${CAT_API_EQUIPMENT}/${sitecode}/${equipmentId}/availability?pageSize=${pageSize}&pageIndex=${pageIndex}`
      )
      .pipe(catchError(handleError));
  }

  /**
   * get availability By Id
   * @param sitecode
   * @param equipmentId
   * @param id
   * @returns
   */
  public getAvailabilitiesById(
    sitecode: string,
    equipmentId: number,
    id: number
  ) {
    return this.http
      .get<PaginatedItems<Reservation>>(
        `${CAT_API_EQUIPMENT}/${sitecode}/${equipmentId}/availability/${id}`
      )
      .pipe(catchError(handleError));
  }

  /**
   * Save or update an Availability
   * @param sitecode to save
   * @param equipmentId to save
   * @param reservation to save
   * @returns an observable of the saved category
   */
  saveAvailability(
    sitecode: string,
    equipmentId: number,
    reservation: Reservation
  ) {
    if (reservation.id) {
      return this.updateAvailability(sitecode, equipmentId, reservation);
    }
    return this.createAvailability(sitecode, equipmentId, reservation);
  }

  /**
   * Create a new availability
   * @param sitecode
   * @param equipmentId
   * @param Reservation
   * @returns
   */
  public createAvailability(
    sitecode: string,
    equipmentId: number,
    reservation: Reservation
  ) {
    return this.http
      .post<EquipmentRequest>(
        `${CAT_API_EQUIPMENT}/${sitecode}/${equipmentId}/availability`,
        reservation
      )
      .pipe(catchError(handleError));
  }

  /**
   * Exclude 1 instance of a recurrence reservation
   * @param sitecode
   * @param equipmentId
   * @param Reservation
   * @param Date
   * @returns
   */
  public recurrenceReservationInstanceExclusion(
    sitecode: string,
    equipmentId: number,
    reservationId: number,
    date: string
  ) {
    return this.http
      .post<EquipmentRequest>(
        `${CAT_API_EQUIPMENT}/${sitecode}/${equipmentId}/reservation/${reservationId}/exclusions`,
        [date]
      )
      .pipe(catchError(handleError));
  }
 /**
   * Cancel series of a recurrence reservation
   * @param sitecode
   * @param equipmentId
   * @param Reservation
   * @param Date
   * @returns
   */
 public recurrenceReservationSeriesDeletion(
  sitecode: string,
  equipmentId: number,
  reservationId: number,
  date: string
) {
  return this.http
    .put<EquipmentRequest>(
      `${CAT_API_EQUIPMENT}/${sitecode}/${equipmentId}/reservation/cancelrecurring/${reservationId}?recurringEndOfDate=${date}`,{}
    )
    .pipe(catchError(handleError));
}
  /**
   * Update an availability
   * @param sitecode
   * @param equipmentId
   * @param Reservation
   * @returns
   */
  public updateAvailability(
    sitecode: string,
    equipmentId: number,
    reservation: Reservation
  ) {
    return this.http
      .put<EquipmentRequest>(
        `${CAT_API_EQUIPMENT}/${sitecode}/${equipmentId}/availability/${reservation.id}`,
        reservation
      )
      .pipe(catchError(handleError));
  }

  /**
   * Delete an Availability
   * @param equipment
   * @returns
   */
  public deleteAvailability(
    sitecode: string,
    id: number,
    availabilityId: number
  ) {
    return this.http
      .delete<EquipmentRequest>(
        `${CAT_API_EQUIPMENT}/${sitecode}/${id}/availability/${availabilityId}`
      )
      .pipe(catchError(handleError));
  }

  /**
   * Update an equipment
   * @param equipment
   * @returns
   */
  private updateEquipment(sitecode: string, equipment: Equipment) {
    const existingEquip: EquipmentUpdateRequest = {
      ...equipment,
    } as EquipmentUpdateRequest;

    return this.http
      .put<EquipmentUpdateRequest>(
        `${CAT_API_EQUIPMENT}/${sitecode}/${equipment.id}`,
        existingEquip
      )
      .pipe(catchError(handleError));
  }

  private equipmentRequestFactory(
    equipment: Equipment,
    sitecode: string
  ): EquipmentRequest {
    return {
      siteCode: sitecode,
      crewId: equipment.crewId,
      sharedCrewIds: equipment.sharedCrewIds,
      categoryId: equipment.categoryId,
      pictureUri: equipment.pictureUri,
      pictureName: equipment.pictureName,
      name: equipment.name,
      unit: equipment.unit,
      brand: equipment.brand,
      model: equipment.model,
      description: equipment.description,
      tags: equipment.tags || [],
      isActive: equipment.isActive,
    } as EquipmentRequest;
  }

  public deleteEquipment(siteCode: number, equipmentId: number) {
    return this.http
      .delete(`${CAT_API_EQUIPMENT}/${siteCode}/${equipmentId}`)
      .pipe(catchError(handleError));
  }

  /**
   * Approve or Reject a Reservation
   * @param sitecode
   * @param equipmentId
   * @param reservationId
   * @param approvalId
   * @param status
   * @param comment
   * @returns
   */
  public completeApproval(
    sitecode: string,
    equipmentId: number,
    reservationId: number,
    approvalId: number,
    status: number,
    comment: string
  ) {
    return this.http
      .put<EquipmentRequest>(
        `${CAT_API_EQUIPMENT}/${sitecode}/${equipmentId}/reservation/${reservationId}/complete/approval/${approvalId}`,
        { status, comment }
      )
      .pipe(catchError(handleError));
  }

  /**
   * search devices
   * @param sitecode
   * @param searchcriteria
   * @param pageSize
   * @param pageIndex
   * @returns
   */
  public searchDevices(
    sitecode: string,
    searchcriteria: string,
    pageSize = 0,
    pageIndex = 1
  ) {
    return this.http
      .get<PaginatedItems<Device>>(
        `${CAT_API_EQUIPMENT}/${sitecode}/searchdevices?pageSize=${pageSize}&pageIndex=${pageIndex}&searchcriteria=${searchcriteria}`
      )
      .pipe(catchError(handleError));
  }

  /**
   * get geo fences by device id
   * @param sitecode
   * @param deviceID
   * @returns
   */
  public getGeofencesByDeviceId(sitecode: string, deviceId: string) {
    return this.http
      .get<PaginatedItems<Geofence>>(
        `${CAT_API_EQUIPMENT}/${sitecode}/getgeofences/${deviceId}`
      )
      .pipe(catchError(handleError));
  }

  /**
   * get geo fences by device id
   * @param sitecode
   * @param deviceID
   * @returns
   */
  public getLastPositionByDeviceId(sitecode: string, deviceId: string) {
    return this.http
      .get<PaginatedItems<DevicePosition>>(
        `${CAT_API_EQUIPMENT}/${sitecode}/getpositions/${deviceId}`
      )
      .pipe(catchError(handleError));
  }
}
