import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { VfmBaseComponent } from '../../../VfmBaseComponent';
import {
  MbscEventcalendarOptions,
  MbscEventcalendarView,
  Notifications,
  MbscCalendarEvent,
  localeEn,
  localeFr,
  MbscPopup,
  formatDate,
  MbscPopupOptions,
} from '@mobiscroll/angular';
import { Equipment, Reservation, Crew } from 'src/app/data';
import { EquipmentService } from '@app/services/data/equipment.service';
import { CrewService } from '@app/services/data/crew.service';
import { Observable, takeUntil } from 'rxjs';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { UserRoleService } from '@app/services/common/user-role.service';
import { ROLES } from '@app/constants/roles.constants';
import { ApprovalStatus } from '@app/enums/approval-status-enum';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from '@app/services/common/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentService as EquipService } from '@app/services/common/equipment.service';
import { UserProfileService } from '@app/services/data/user-profile.service';

@Component({
  selector: 'app-equipment-detail',
  templateUrl: './equipment-detail.component.html',
  styleUrls: ['./equipment-detail.component.scss'],
})
export class EquipmentDetailComponent
  extends VfmBaseComponent
  implements OnInit, OnChanges
{
  @Input() equipment!: Equipment;
  @Input() reservationStartDate?: moment.Moment;
  @Input() reservationDuration?: number;
  @Input() sidenav!: any;
  @Input() outsideEquipmentContext = false;
  @Output() updateFavorite: EventEmitter<boolean> = new EventEmitter<boolean>();

  locale = this.profile.language === 0 ? localeEn : localeFr;
  isAdmin = false;
  crew!: Crew;
  view = 'day';
  reservations!: Reservation[];
  invalidDate: any = [];
  timer: any;
  time = '';
  hoverEvent!: Reservation | any;
  anchor: HTMLElement | undefined;
  selectedTab = 'calendar';
  isFavorite: boolean = false;

  calView: MbscEventcalendarView = {
    schedule: {
      allDay: false,
      type: 'day',
      size: 3,
      startTime: '00:00',
      endTime: '24:00',
    },
  };
  myEvents: MbscCalendarEvent[] = [];

  popupOptions: MbscPopupOptions = {
    display: 'anchored',
    touchUi: false,
    showOverlay: false,
    contentPadding: false,
    closeOnOverlayClick: false,
    width: 350,
  };

  eventSettings: MbscEventcalendarOptions = {
    showEventTooltip: false,
    theme: 'ios',
    themeVariant: 'light',
    onEventHoverIn: (args, inst) => {
      const event: any = args.event;

      console.log('event', event);

      this.time =
        formatDate('hh:mm A', new Date(event.start)) +
        ' - ' +
        formatDate('hh:mm A', new Date(event.end));

      this.hoverEvent = event;

      clearTimeout(this.timer);
      this.timer = null;

      this.anchor = args.domEvent.target;

      this.tooltip.open();
    },
    onEventHoverOut: () => {
      if (!this.timer) {
        this.timer = setTimeout(() => {
          this.tooltip.close();
        }, 200);
      }
    },
    onEventClick: (event) => {},
  };

  @ViewChild('popup', { static: false })
  tooltip!: MbscPopup;

  constructor(
    private notify: Notifications,
    private equipmentService: EquipmentService,
    private crewService: CrewService,
    private router: Router,
    private route: ActivatedRoute,
    private roleUserService: UserRoleService,
    private toastrService: ToastrService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private equipService: EquipService,
    private userProfileService: UserProfileService
  ) {
    super();
  }

  async ngOnInit() {
    const roles = this.roleUserService.getRoles() || [];
    const siteManagerRole = ROLES.SITE_MANAGER.replace(
      '{siteId}',
      this.site.id
    );
    const sitePlanerRole = ROLES.SITE_PLANER.replace('{siteId}', this.site.id);
    const requiredRoles = [siteManagerRole, sitePlanerRole, ROLES.ADMIN];

    if (requiredRoles.some((r) => roles.includes(r))) {
      this.isAdmin = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.equipment && changes.equipment.currentValue) {
      this.selectedTab = 'calendar';
      this.isFavorite = this.equipment.isFavorite;

      this.getReservationsByEquipment();
      this.loadRestrictions();
      this.getCrew();
    }
  }

  getReservationsByEquipment() {
    this.equipmentService
      .getReservationsByEquipment(this.site.code, this.equipment.id!)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.reservations = result.data;
        const reservations = this.reservations.map(
          (reservation: Reservation) => {
            return {
              id: reservation.id,
              start: moment(reservation.startDate),
              end: moment(reservation.endDate),
              recurring: reservation.rRule,
              recurringException: [
                ...reservation.exclusions,
                ...reservation.reccurenceExclusions.filter(Boolean),
              ],
              text: `<div style="text-align: center; margin-bottom: 10px;"></div><div class="my-block"><i class="fa-solid fa-truck mr-1"></i>${
                this.equipment.name
              }</div><div class="my-desc"><i class="fa-solid fa-user mr-1"></i>${
                reservation.userName
              }</div></div><div><i class="fa-solid fa-comment mr-1"></i>${
                reservation.description || 'N/A'
              }</div>`,
              color:
                reservation.approvalStatus === ApprovalStatus.pending
                  ? '#454444'
                  : this.getReservationColor(reservation.userId!),
              editable: false,
            };
          }
        );
        this.myEvents = [...reservations];
      });
  }

  setTab(tabName: string) {
    this.selectedTab = tabName;
  }

  setOptionTab(e: any) {
    this.selectedTab = e.target.value;
  }

  loadRestrictions() {
    this.equipmentService
      .getAvailabilitiesByEquipmentId(this.site.code, this.equipment.id!, 0, 0)
      .subscribe((result: any) => {
        let invalidRestrictions = result.data.map(
          (restriction: Reservation) => {
            return {
              start: moment(
                restriction.startDate,
                'YYYY-MM-DD[T]HH:mm:ss'
              ).format('HH:mm'),
              end: moment(restriction.endDate, 'YYYY-MM-DD[T]HH:mm:ss').format(
                'HH:mm'
              ),
              title: this.getTypeRestriction(restriction.availabilityType!),
              recurring:
                restriction.rRule + ';DTSTART=' + restriction.startDate,
            };
          }
        );

        this.invalidDate = [...invalidRestrictions];
      });
  }

  getTypeRestriction(availabilityType: number) {
    let type = '';

    switch (availabilityType) {
      case 1:
        type = 'All Restricted';
        break;

      case 2:
        type = 'Crew Restricted';
        break;

      case 3:
        type = 'Soft Restricted';
        break;
    }

    return type;
  }

  getReservationColor(userId: number): string {
    return userId === this.profile.id ? '#008000' : '#F1C400';
  }

  getCrew() {
    this.crewService
      .getCrewById(this.equipment.crewId, this.site.code)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.crew = result;

        const userCrewManager = this.crew.managers?.find((manager) => {
          return manager.id === this.profile.id;
        });

        if (userCrewManager) {
          this.isAdmin = true;
        }
      });
  }

  getCustomRule(rRule: string) {
    if (rRule) {
      // convert rRule string to object
      const arrOfObjRules = rRule
        .split(';')
        .map((x) => x.split('=').map((y) => y.trim()))
        .reduce((a: any, x: any) => {
          a[x[0]] = x[1];
          return a;
        }, {});

      return arrOfObjRules;
    }
  }

  mouseEnter(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  mouseLeave(): void {
    this.timer = setTimeout(() => {
      this.tooltip.close();
    }, 200);
  }

  addReservation() {
    const route = [this.equipment.id, 'new'];
    
    if(this.outsideEquipmentContext)
      route.unshift('equipment');
    
    let state: any;

    if(this.reservationStartDate)
    {
      state = {
        startDate: this.reservationStartDate.format('YYYY-MM-DD HH:mm'),
        duration: this.reservationDuration || 1
      };
    }

    this.router.navigate(route, {
      relativeTo: this.route,
      state
    });
  }

  editEquipment() {
    if (this.outsideEquipmentContext) {
      this.router.navigate([`equipment/modify/${this.equipment.id}`], {
        relativeTo: this.route,
      });
    } else {
      this.router.navigate([`modify/${this.equipment.id}`], {
        relativeTo: this.route,
      });
    }
  }

  editRestriction() {
    this.router.navigate([`restriction/${this.equipment.id}`], {
      relativeTo: this.route,
    });
  }

  deleteEquipment() {
    this.equipmentService
      .deleteEquipment(this.site.code, this.equipment.id!)
      .subscribe(() => {
        this.equipService.updateEquipment(true);
        this.sidenav.close();
        this.toastrService.success(
          this.translate.instant(
            'features.equipment.EQUIPMENT_SUCCESSFULLY_DELETED'
          )
        );
      });
  }

  addFavoriteEquipment(id: number) {
    this.userProfileService
      .addFavoriteEquipment(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.isFavorite = true;
        this.updateFavorite.emit(true);
      });
  }

  removeFavoriteEquipment(id: number) {
    this.userProfileService
      .deleteFavoriteEquipment(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.isFavorite = false;
        this.updateFavorite.emit(true);
      });
  }

  toggleFavorite(e: any, id: number) {
    e.stopImmediatePropagation();

    if (this.isFavorite) {
      this.removeFavoriteEquipment(id);
    } else {
      this.addFavoriteEquipment(id);
    }
  }

  confirmRemoveEquipment(event: any) {
    event.stopImmediatePropagation();

    this.dialogService
      .confirm(
        this.translate.instant(
          'features.equipment.CONFIRM_REMOVE_DIALOG.TITLE'
        ),
        this.translate.instant(
          'features.equipment.CONFIRM_REMOVE_DIALOG.DESCRIPTION'
        )
      )
      .subscribe((result: any) => {
        if (result) {
          this.deleteEquipment();
        }
      });
  }
}
