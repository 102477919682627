import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MenuService } from '../../../core/services/common/menu.service';
import { MenuItem } from 'src/app/data/menuitem.model';
import { TranslateService } from '@ngx-translate/core';
import { UserRoleService } from '@app/services/common/user-role.service';
import { ROLES } from '@app/constants/roles.constants';
import { VfmBaseComponent } from 'src/app/features/VfmBaseComponent';
import { ProfileService } from 'src/app/core/services/common/profile.service';
import { environment } from '@env';

@Component({
  selector: 'app-sitenav-menu',
  templateUrl: './sitenav-menu.component.html',
  styleUrls: ['./sitenav-menu.component.scss'],
})
export class SitenavMenuComponent extends VfmBaseComponent implements OnInit {
  @Output() closeCanvasMenu: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  env = environment;
  isSidenavCollapse = false;
  isOffCanvasMenu = false;
  isOffCanvasMenuDialog = false;
  adminMenuItem = {
    id: 1,
    label: 'ADMIN',
    routerLink: '/admin',
    iconClass: 'fa-solid fa-house-lock mr-4',
  };

  menu: MenuItem[] = [
    {
      id: 0,
      label: 'HOME',
      routerLink: '/home',
      iconClass: 'fa-solid fa-house mr-4',
    },
    {
      id: 3,
      label: 'RESERVATIONS',
      routerLink: '/reservation',
      iconClass: 'fa-solid fa-calendar-days ml-1 mr-4',
    },
    {
      id: 4,
      label: 'EQUIPMENT',
      routerLink: '/equipment',
      iconClass: 'fa-solid fa-truck mr-3',
    },
    {
      id: 5,
      label: 'CREW',
      routerLink: '/crew',
      iconClass: 'fa-solid fa-people-group mr-3 ',
    },
    {
      id: 6,
      label: 'SETTINGS',
      routerLink: '/settings',
      iconClass: 'fa-solid fa-gear ml-[3px] mr-4',
    },
  ];

  constructor(
    private menuService: MenuService,
    private translate: TranslateService,
    private roleUserService: UserRoleService,
    private profileService: ProfileService
  ) {
    super();

    this.menuService.getModifyToggleSidenav().subscribe((toggle) => {
      this.isSidenavCollapse = toggle;
    });

    this.menuService.getModifyDefaultSite().subscribe(() => {
      this.manageMenuItem();
    });

    this.profileService.getModifyToken().subscribe(() => {
      this.manageMenuItem();
    });
  }

  ngOnInit() {}

  closeMenu() {
    this.closeCanvasMenu.emit(false);
  }

  manageMenuItem() {
    const localStorageSite = localStorage.getItem('VFM_SITE');
    let siteId = this.profile?.siteId;
    
    if (localStorageSite) {
      try {
        const site = JSON.parse(localStorageSite);
        siteId = site.id;
      } catch (error) {}
    }

    if (siteId) {
      const roles = this.roleUserService.getRoles();
      const siteRole = ROLES.SITE_MANAGER.replace('{siteId}', siteId);
      const requiredRoles = [siteRole, ROLES.ADMIN];

      if (requiredRoles.some((r) => roles.includes(r))) {
        const adminItemMenu = this.menu.find((obj) => obj.id === 1);

        if (!adminItemMenu) {
          this.menu.splice(1, 0, this.adminMenuItem);
        }
      } else {
        this.menu = this.menu.filter((obj) => obj.id !== 1);
      }
    }
  }
}
