import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { VfmBaseComponent } from '../VfmBaseComponent';
import { EquipmentService } from '@app/services/data/equipment.service';
import { EquipmentService as EquipService } from '@app/services/common/equipment.service';
import { Equipment } from 'src/app/data';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription, takeUntil } from 'rxjs';
import { AvailableEquipmentCriteria } from './models/available-equipment-criteria';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends VfmBaseComponent implements OnInit, OnDestroy {
  @ViewChild('drawer') drawer!: MatSidenav;

  pageIconClass = 'fa-solid fa-home';
  pageTitle = '';
  selectedEquipment!: Equipment;
  equipments!: Equipment[];
  prevLink = '';
  searchCriteria: AvailableEquipmentCriteria = { startDate: moment(), duration: 1 }
  equipmentModificationSubscription: Subscription;
  showFilters = false;

  constructor(
    private equipmentService: EquipmentService,
    private equipService: EquipService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService) {
    super();

    this.pageTitle = this.translate.instant('features.home.TITLE');

    this.equipmentModificationSubscription = this.equipService.getModifyEquipment().subscribe(() => {
      this.loadEquipments();
    });
  }

  ngOnInit(): void {
    this.loadEquipments();
  }

  openDrawer(equipment: Equipment) {
    this.selectedEquipment = equipment;
    this.drawer.toggle();
  }

  closeDrawer() {
    this.drawer.toggle();
  }

  loadEquipments() {
    const startDate = this.searchCriteria.startDate.format('YYYY-MM-DD HH:mm')
    this.equipmentService
      .getAvailableEquipments(this.site.code, startDate, this.searchCriteria.duration, 0, 0)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.equipments = result.data.filter((equip) => {
          return equip.isActive === true;
        });

        this.selectEquipment(this.equipments[0]);
      });
  }

  selectEquipment(equipment: Equipment) {
    this.equipmentService
      .getEquipmentById(equipment.id!, this.site.code)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.selectedEquipment = result;
      });
  }

  addReservation(e: any, equipmentId: number | null) {
    e.stopPropagation();
    
    if(equipmentId === null)
      return;

    const route = [equipmentId, 'new'];
    
    this.router.navigate(route, {
      relativeTo: this.route,
      state: {startDate: this.searchCriteria.startDate.format('YYYY-MM-DD HH:mm'), duration: this.searchCriteria.duration}
    }
  );
  }

  onSearchCriteriaChange(searchCriteria: AvailableEquipmentCriteria) {
    this.searchCriteria = searchCriteria;
    this.loadEquipments();
  }

  toggleFilters(event: any) {
    event.stopImmediatePropagation();

    this.showFilters = !this.showFilters;
  }

  override ngOnDestroy(): void {
    this.equipmentModificationSubscription.unsubscribe();
    super.ngOnDestroy();
  }
}
