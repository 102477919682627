import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { localeEn, localeFr, MbscDatepickerOptions } from '@mobiscroll/angular';
import * as moment from 'moment';
import { VfmBaseComponent } from '../../VfmBaseComponent';
import { AvailableEquipmentCriteria } from '../models/available-equipment-criteria';

@Component({
  selector: 'app-home-search',
  templateUrl: './home-search.component.html',
  styleUrls: ['./home-search.component.scss']
})
export class HomeSearchComponent extends VfmBaseComponent implements OnInit {
  @Input() searchCriteria: AvailableEquipmentCriteria = { startDate: moment(), duration: 1 };
  @Input() showFilters = false;
  @Output() searchCriteriaChange = new EventEmitter<AvailableEquipmentCriteria>();

  startDate: moment.Moment;
  duration: number;
  hours: number[];
  locale = this.profile.language === 0 ? localeEn : localeFr;

  datepickerOptionsStartDate: MbscDatepickerOptions = {
    moment: moment,
    returnFormat: 'moment',
    select: 'date',
    controls: ['calendar', 'time'],
    stepMinute: 5,
    timeFormat: 'HH:mm',
    invalid: [
      {
        start: '1900-01-01',
        end: moment().add(-1, 'day').format('YYYY-MM-dd'),
      },
    ],
    locale: this.locale,
  };

  constructor() {
    super();

    this.hours = Array.from({ length: 12 }, (_value, index) => index + 1);
    this.startDate = this.searchCriteria.startDate;
    this.duration = this.searchCriteria.duration;
  }

  ngOnInit(): void {
  }

  startDateChange(startDate: moment.Moment) {
    this.startDate = startDate;
    this.searchCriteria = { ...this.searchCriteria, startDate };
    this.searchCriteriaChange.emit(this.searchCriteria);
  }

  durationChange(duration: number) {
    this.duration = duration;
    this.searchCriteria = { ...this.searchCriteria, duration };
    this.searchCriteriaChange.emit(this.searchCriteria);
  }

  clearDuration(e: any) {
    e.stopImmediatePropagation();
    if (this.duration !== 1) {
      this.durationChange(1);
    }
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
  }
}
